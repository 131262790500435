import React from "react";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FeedIcon from "@mui/icons-material/Feed";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import "./Features.css";

const Features = () => {
  return (
    <div className="dgf-container">
      <h5># Step into the World of Esports & Gaming</h5>
      <h1>Building Heroes in the Gaming Universe</h1>
      <div className="dgf-c">
        <div className="dgf">
          <VideogameAssetIcon className="icon" />
          <h4>Gaming Recharge</h4>
          <p>Recharge all types of games easily on our website</p>
        </div>
        <div className="dgf">
          <NewspaperIcon className="icon" />
          <h4>Gaming News</h4>
          <p>Stay informed with the latest news in the gaming industry</p>
        </div>
        <div className="dgf">
          <SlowMotionVideoIcon className="icon" />
          <h4>Live Stream</h4>
          <p>Watch live game coverage through integration with us</p>
        </div>
        <div className="dgf">
          <EmojiEventsIcon className="icon" />
          <h4>Great Tournament</h4>
          <p>Get updates on upcoming gaming tournaments</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
